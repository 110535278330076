import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ChevronIcon = () => {
  return (
    <SvgIcon
      fontSize="small"
      //style={{ color: 'white' }}
      x="0px"
      y="0px"
      viewBox="0 0 30.727 30.727"
      //style= "enable-background:new 0 0 30.727 30.727;"
    >
      <g>
        <path
          d="M29.994,10.183L15.363,24.812L0.733,10.184c-0.977-0.978-0.977-2.561,0-3.536c0.977-0.977,2.559-0.976,3.536,0
                    l11.095,11.093L26.461,6.647c0.977-0.976,2.559-0.976,3.535,0C30.971,7.624,30.971,9.206,29.994,10.183z"
        />
      </g>
    </SvgIcon>
  );
};

const MenuIcon = () => {
  return (
    <SvgIcon
      fontSize="inherit"
      viewBox="0 0 512 512"
      style={{ color: 'white', marginBottom: 5 }}
    >
      <g>
        <g>
          <path d="M492,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,236,492,236z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M492,76H20C8.954,76,0,84.954,0,96s8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,76,492,76z" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M492,396H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20
                        C512,404.954,503.046,396,492,396z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

const OutlinedArrowIcon = () => {
  return (
    <SvgIcon fontSize="small" viewBox="0 0 512 512" style={{ marginTop: 5 }}>
      <path d="m511.824219 255.863281-233.335938-255.863281v153.265625h-27.105469c-67.144531 0-130.273437 26.148437-177.753906 73.628906-47.480468 47.480469-73.628906 110.609375-73.628906 177.757813v107.347656l44.78125-49.066406c59.902344-65.628906 144.933594-103.59375 233.707031-104.457032v153.253907zm-481.820313 179.003907v-30.214844c0-59.132813 23.027344-114.730469 64.839844-156.542969s97.40625-64.839844 156.539062-64.839844h57.105469v-105.84375l162.734375 178.4375-162.734375 178.441407v-105.84375h-26.917969c-94.703124 0-185.773437 38.652343-251.566406 106.40625zm0 0" />
    </SvgIcon>
  );
};

const ToolBoxIcon = () => {
  return (
    <SvgIcon
      fontSize="medium"
      viewBox="0 0 512 512"
      style={{ marginTop: 3, marginRight: 3 }}
    >
      <g>
        <g>
          <path
            d="M467,84h-62v-1c0-24.813-20.187-45-45-45H152c-24.813,0-45,20.187-45,45v1H45c-24.813,0-45,20.187-45,45v300
			c0,24.813,20.187,45,45,45h422c24.813,0,45-20.187,45-45V129C512,104.187,491.813,84,467,84z M482,429c0,8.271-6.729,15-15,15H45
			c-8.271,0-15-6.729-15-15V129c0-8.271,6.729-15,15-15h92V83c0-8.271,6.729-15,15-15h208c8.271,0,15,6.729,15,15v31h92
			c8.271,0,15,6.729,15,15V429z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M286,98h-60c-24.813,0-45,20.187-45,45s20.187,45,45,45h60c24.813,0,45-20.187,45-45S310.813,98,286,98z M286,158h-60
			c-8.271,0-15-6.729-15-15s6.729-15,15-15h60c8.271,0,15,6.729,15,15S294.271,158,286,158z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M242.621,324.48c-13.828-13.827-34.485-16.748-51.209-8.781l-34.372-34.372l4.393-4.393v-40.711l-30-30H108.25v30h10.757
			l12.426,12.426v15.858l-12.426,12.426h-15.858l-12.426-12.426V253.75h-30v23.184l30,30h40.711l4.393-4.393l34.372,34.372
			c-7.966,16.724-5.045,37.382,8.782,51.209c8.773,8.772,20.296,13.158,31.82,13.158c11.523,0,23.047-4.386,31.82-13.159
			C260.166,370.575,260.166,342.026,242.621,324.48z M221.408,366.907c-5.848,5.849-15.364,5.85-21.213,0
			c-5.849-5.849-5.849-15.365,0-21.213c2.924-2.924,6.766-4.387,10.607-4.387s7.683,1.462,10.606,4.386
			C227.256,351.542,227.256,361.059,221.408,366.907z"
          />
        </g>
      </g>
      <g>
        <g>
          <rect x="286" y="230" width="156" height="30" />
        </g>
      </g>
      <g>
        <g>
          <rect x="286" y="290" width="86" height="30" />
        </g>
      </g>
      <g>
        <g>
          <rect x="286" y="350" width="116" height="30" />
        </g>
      </g>
    </SvgIcon>
  );
};

const BookIcon = () => {
  return (
    <SvgIcon
      fontSize="medium"
      viewBox="0 0 512 512"
      style={{ marginTop: 4, marginRight: 3 }}
    >
      <g>
        <path d="m440.124 121.619c-19.94-1.531-42.504-6.061-67.065-13.462-29.507-8.894-58.149-7.521-85.133 4.078-3.806 1.636-5.564 6.047-3.929 9.853 1.636 3.804 6.042 5.566 9.853 3.929 23.651-10.167 48.846-11.345 74.881-3.498 25.595 7.714 49.229 12.442 70.245 14.056.195.016.39.022.582.022 3.882 0 7.168-2.991 7.47-6.926.317-4.13-2.774-7.735-6.904-8.052z" />
        <path d="m440.123 153.494c-19.935-1.53-42.498-6.059-67.064-13.462-29.507-8.894-58.149-7.521-85.133 4.078-3.806 1.636-5.564 6.047-3.929 9.853 1.636 3.805 6.042 5.566 9.853 3.929 23.651-10.167 48.846-11.345 74.881-3.498 25.601 7.715 49.234 12.444 70.246 14.056.195.016.389.022.581.022 3.882 0 7.168-2.991 7.47-6.927.317-4.13-2.774-7.734-6.905-8.051z" />
        <path d="m440.124 312.871c-19.941-1.531-42.505-6.061-67.065-13.463-29.505-8.894-58.146-7.52-85.133 4.079-3.806 1.636-5.564 6.047-3.929 9.853 1.636 3.804 6.042 5.566 9.853 3.929 23.652-10.167 48.847-11.343 74.881-3.499 25.594 7.714 49.228 12.443 70.245 14.057.195.016.39.022.582.022 3.882 0 7.168-2.991 7.47-6.926.317-4.13-2.774-7.735-6.904-8.052z" />
        <path d="m440.124 344.746c-19.94-1.531-42.504-6.061-67.065-13.462-29.507-8.893-58.149-7.521-85.133 4.078-3.806 1.636-5.564 6.047-3.929 9.853 1.636 3.805 6.042 5.566 9.853 3.929 23.651-10.167 48.846-11.345 74.881-3.498 25.595 7.714 49.229 12.442 70.245 14.056.195.016.39.022.582.022 3.882 0 7.168-2.991 7.47-6.926.317-4.13-2.774-7.735-6.904-8.052z" />
        <path d="m440.124 185.37c-19.941-1.531-42.505-6.061-67.065-13.463-29.505-8.893-58.146-7.521-85.133 4.079-3.806 1.636-5.564 6.047-3.929 9.853 1.636 3.804 6.042 5.565 9.853 3.929 23.652-10.167 48.847-11.344 74.881-3.499 25.594 7.714 49.228 12.443 70.245 14.057.195.016.39.022.582.022 3.882 0 7.168-2.991 7.47-6.926.317-4.13-2.774-7.735-6.904-8.052z" />
        <path d="m440.123 249.119c-19.935-1.529-42.498-6.058-67.064-13.461-29.505-8.894-58.148-7.521-85.133 4.078-3.806 1.636-5.564 6.047-3.929 9.853 1.636 3.805 6.042 5.566 9.853 3.929 23.65-10.166 48.846-11.344 74.881-3.498 25.601 7.715 49.234 12.444 70.246 14.057.195.015.389.021.581.021 3.882 0 7.168-2.991 7.47-6.927.317-4.13-2.774-7.734-6.905-8.052z" />
        <path d="m440.124 217.245c-19.94-1.531-42.504-6.061-67.065-13.462-29.504-8.894-58.148-7.521-85.133 4.078-3.806 1.636-5.564 6.047-3.929 9.853 1.636 3.804 6.042 5.566 9.853 3.929 23.654-10.167 48.847-11.344 74.881-3.498 25.595 7.714 49.229 12.442 70.245 14.056.195.016.39.022.582.022 3.882 0 7.168-2.991 7.47-6.926.317-4.13-2.774-7.735-6.904-8.052z" />
        <path d="m440.123 280.994c-19.938-1.529-42.502-6.059-67.064-13.461-29.505-8.893-58.146-7.521-85.133 4.079-3.806 1.636-5.564 6.047-3.929 9.853 1.636 3.804 6.042 5.565 9.853 3.929 23.652-10.167 48.847-11.343 74.881-3.499 25.597 7.715 49.23 12.444 70.246 14.057.195.015.389.021.581.021 3.882 0 7.168-2.991 7.47-6.927.317-4.13-2.774-7.734-6.905-8.052z" />
        <path d="m72.44 136.597c.192 0 .387-.007.582-.022 21.017-1.613 44.65-6.342 70.245-14.056 26.035-7.846 51.229-6.67 74.881 3.498 3.805 1.636 8.217-.124 9.853-3.929 1.636-3.806-.123-8.217-3.929-9.853-26.984-11.6-55.627-12.971-85.133-4.078-24.562 7.401-47.125 11.931-67.065 13.462-4.13.316-7.221 3.922-6.903 8.052.301 3.935 3.588 6.926 7.469 6.926z" />
        <path d="m72.44 168.472c.192 0 .387-.007.581-.022 21.012-1.611 44.646-6.341 70.246-14.056 26.035-7.846 51.229-6.671 74.881 3.498 3.805 1.635 8.217-.124 9.853-3.929 1.636-3.806-.123-8.217-3.929-9.853-26.984-11.6-55.627-12.97-85.133-4.078-24.566 7.403-47.13 11.932-67.064 13.462-4.131.316-7.222 3.921-6.904 8.051.301 3.936 3.588 6.927 7.469 6.927z" />
        <path d="m72.44 327.849c.192 0 .387-.007.582-.022 21.018-1.613 44.651-6.343 70.245-14.057 26.036-7.848 51.229-6.668 74.881 3.499 3.805 1.635 8.217-.124 9.853-3.929 1.636-3.806-.123-8.217-3.929-9.853-26.984-11.6-55.627-12.971-85.133-4.079-24.561 7.402-47.124 11.932-67.065 13.463-4.13.316-7.221 3.922-6.903 8.052.301 3.935 3.588 6.926 7.469 6.926z" />
        <path d="m72.44 359.724c.192 0 .387-.007.582-.022 21.017-1.613 44.65-6.342 70.245-14.056 26.035-7.846 51.229-6.671 74.881 3.498 3.805 1.634 8.217-.124 9.853-3.929 1.636-3.806-.123-8.217-3.929-9.853-26.984-11.6-55.627-12.971-85.133-4.078-24.562 7.401-47.125 11.931-67.065 13.462-4.13.316-7.221 3.922-6.903 8.052.301 3.935 3.588 6.926 7.469 6.926z" />
        <path d="m72.44 200.348c.192 0 .387-.007.582-.022 21.018-1.613 44.651-6.343 70.245-14.057 26.036-7.847 51.229-6.668 74.881 3.499 3.805 1.635 8.217-.124 9.853-3.929 1.636-3.806-.123-8.217-3.929-9.853-26.984-11.6-55.627-12.971-85.133-4.079-24.561 7.402-47.124 11.932-67.065 13.463-4.13.316-7.221 3.922-6.903 8.052.301 3.935 3.588 6.926 7.469 6.926z" />
        <path d="m72.44 264.098c.192 0 .387-.007.581-.021 21.012-1.612 44.646-6.342 70.246-14.057 26.035-7.846 51.229-6.671 74.881 3.498 3.805 1.635 8.217-.124 9.853-3.929 1.636-3.806-.123-8.217-3.929-9.853-26.983-11.599-55.625-12.971-85.133-4.078-24.566 7.403-47.13 11.932-67.064 13.461-4.131.317-7.222 3.922-6.904 8.052.301 3.936 3.588 6.927 7.469 6.927z" />
        <path d="m72.44 232.223c.192 0 .387-.007.582-.022 21.017-1.613 44.65-6.342 70.245-14.056 26.034-7.845 51.228-6.671 74.881 3.498 3.805 1.634 8.217-.124 9.853-3.929 1.636-3.806-.123-8.217-3.929-9.853-26.986-11.6-55.627-12.973-85.133-4.078-24.562 7.401-47.125 11.931-67.065 13.462-4.13.316-7.221 3.922-6.903 8.052.301 3.935 3.588 6.926 7.469 6.926z" />
        <path d="m72.44 295.973c.192 0 .387-.007.581-.021 21.016-1.612 44.649-6.342 70.246-14.057 26.036-7.848 51.229-6.668 74.881 3.499 3.805 1.636 8.217-.124 9.853-3.929 1.636-3.806-.123-8.217-3.929-9.853-26.984-11.6-55.627-12.972-85.133-4.079-24.562 7.402-47.127 11.932-67.064 13.461-4.131.317-7.222 3.922-6.904 8.052.301 3.936 3.588 6.927 7.469 6.927z" />
        <path d="m493.68 93.315h-11.741v-6.843c0-9.59-8.181-17.176-17.762-16.413-11.067.885-35.3.939-73.262-8.687-4.017-1.021-8.097 1.412-9.113 5.426-1.019 4.016 1.411 8.096 5.426 9.113 38.054 9.649 63.502 10.276 78.148 9.099.853-.06 1.562.603 1.562 1.462v302.656c-28.41 3.065-66.362-5.068-93.88-13.36-61.461-18.523-101.194 13.3-109.56 17.834v-299.79c8.104-7.601 40.767-34.523 89.38-26.701 4.087.664 7.938-2.122 8.597-6.212.659-4.089-2.122-7.938-6.212-8.597-51.045-8.224-86.269 16.71-99.283 28.065-29.839-25.878-72.521-37.373-117.041-23.953-47.783 14.4-77.601 14.725-91.118 13.646-9.594-.77-17.761 6.84-17.761 16.412v6.843h-11.743c-10.1 0-18.317 8.216-18.317 18.317v304.143c0 10.101 8.218 18.317 18.318 18.317h194.326c2.912 0 5.553 1.607 6.729 4.097 14.556 30.796 58.641 30.909 73.25 0 1.177-2.488 3.818-4.097 6.73-4.097h194.326c10.1 0 18.316-8.217 18.316-18.317v-142.235c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v142.234c0 1.829-1.487 3.317-3.316 3.317h-194.325c-8.684 0-16.648 4.979-20.291 12.687-9.168 19.395-36.926 19.472-46.128.001-3.642-7.707-11.606-12.688-20.291-12.688h-194.326c-1.83 0-3.318-1.488-3.318-3.317v-304.142c0-1.829 1.488-3.317 3.317-3.317h11.743v287.214c0 3.403 2.291 6.38 5.581 7.25 1.426.378 35.778 9.005 107.627-12.649 34.763-10.476 68.315-4.052 94.936 14.721 10.293 7.257 24.622 7.737 35.593-.001 16.077-11.338 49.785-28.324 94.935-14.72 71.852 21.653 106.201 13.026 107.627 12.649 3.29-.87 5.581-3.847 5.581-7.25v-287.214h11.741c1.829 0 3.317 1.488 3.317 3.317v126.908c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-126.908c-.001-10.101-8.218-18.317-18.318-18.317zm-448.62-6.843c0-.841.687-1.523 1.562-1.462 14.57 1.172 46.505.876 96.646-14.235 40.607-12.234 79.401-1.143 105.231 23.043v299.784c-4.302-2.329-46.474-36.849-109.56-17.833-4.045 1.219-8.491 2.485-12.605 3.584-.05.013-.1.027-.149.04-24.15 6.442-56.276 12.415-81.126 9.739.001-20.846.001-297.07.001-302.66z" />
      </g>
    </SvgIcon>
  );
};

const WebDevIcon = () => {
  return (
    <SvgIcon
      fontSize="medium"
      viewBox="0 0 512 512"
      style={{ marginTop: 3, marginRight: 3 }}
    >
      <path d="m467 1h-422c-24.813 0-45 20.187-45 45v420c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45v-420c0-24.813-20.187-45-45-45zm-422 30h422c8.271 0 15 6.729 15 15v75h-452v-75c0-8.271 6.729-15 15-15zm422 450h-422c-8.271 0-15-6.729-15-15v-315h452v315c0 8.271-6.729 15-15 15z" />
      <path d="m306.909 197.213c-7.614-3.263-16.433.264-19.696 7.878l-90 210c-3.264 7.614.264 16.433 7.878 19.696 7.617 3.264 16.434-.266 19.696-7.878l90-210c3.264-7.615-.264-16.433-7.878-19.696z" />
      <path d="m177.713 246.629c-5.176-6.469-14.617-7.517-21.083-2.342l-75 60c-7.499 5.997-7.504 17.424 0 23.426l75 60c6.469 5.176 15.91 4.126 21.083-2.342 5.175-6.469 4.127-15.909-2.343-21.083l-60.358-48.288 60.358-48.287c6.47-5.175 7.518-14.614 2.343-21.084z" />
      <path d="m430.37 304.287-75-60c-6.469-5.176-15.909-4.127-21.083 2.342-5.175 6.469-4.127 15.909 2.343 21.083l60.358 48.288-60.358 48.287c-6.47 5.175-7.518 14.614-2.343 21.083 5.182 6.476 14.623 7.512 21.083 2.342l75-60c7.499-5.997 7.504-17.423 0-23.425z" />
      <circle cx="76" cy="76" r="15" />
      <circle cx="136" cy="76" r="15" />
      <circle cx="196" cy="76" r="15" />
      <path d="m346 91h90c8.284 0 15-6.716 15-15s-6.716-15-15-15h-90c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
    </SvgIcon>
  );
};

const ArrowIcon = () => {
  return (
    <SvgIcon
      fontSize="inherit"
      viewBox="0 0 512 512"
      style={{ marginRight: 2 }}
    >
      <path
        d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
			c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
			l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
			c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
      />
    </SvgIcon>
  );
};

export const GitHubIcon = () => {
  return (
    <SvgIcon fontSize="large" viewBox="0 0 512 512" style={{ color: 'black' }}>
      <path
        d="m256 0c-140.699219 0-256 116.300781-256 257 0 139.882812 114.25 255 256 255 141.574219 0 
                256-114.945312 256-255 0-140.699219-115.300781-257-256-257zm45 477.5c-14.398438 3-29.699219 4.5-45 
                4.5s-30.601562-1.5-45-4.5v-70.199219c0-16.800781 4.5-22.800781 10.5-30.902343 3.054688-3.492188 4.898438-6.625 
                18.597656-27.296876l-23.097656-3.601562c-59.402344-8.699219-82.800781-39.601562-92.101562-63.601562-12-32.097657-5.699219-72.300782 
                15.902343-97.796876 3.300781-3.902343 6-10.503906 3.601563-17.402343-4.503906-13.800781-3.902344-35.699219-.902344-44.101563 15.90625 
                2.273438 32.261719 13.667969 45.902344 21.902344 6.285156 3.667969 9.582031 2.699219 12.597656 3 10.960938-2.28125 28.058594-7.800781 
                54.300781-7.800781 16.199219 0 33.300781 2.398437 50.101563 7.199219 3.003906-.070313 7.832031 2.484374 16.199218-2.398438 14.257813-8.6875 
                30.058594-19.691406 45.898438-21.902344 3 8.402344 3.601562 30.300782-.898438 44.101563-2.402343 6.898437.296876 13.5 3.601563 17.402343 
                21.597656 25.5 27.898437 65.699219 15.898437 97.796876-9.300781 24-32.699218 54.902343-92.101562 63.601562l-23.097656 3.601562c14.160156 
                21.367188 15.652344 23.929688 18.601562 27.296876 5.996094 8.101562 10.496094 14.101562 10.496094 30.902343zm30-8.699219v-61.5c0-17.101562-3.601562-28.5-8.402344-36.902343 
                45.601563-12.296876 78.003906-39.300782 92.402344-78 15.300781-40.796876 8.402344-89.398438-17.101562-123 4.503906-20.097657 
                4.503906-52.199219-6.296876-67.199219-4.800781-6.597657-11.402343-10.199219-19.800781-10.199219-.300781 0-.300781 0-.300781 0-23.261719 
                1.257812-41.570312 12.972656-61.199219 24.898438-18-4.800782-36.300781-7.199219-54.601562-7.199219-18.597657 0-37.199219 2.699219-53.695313 
                7.199219-20.664062-12.460938-38.796875-23.671876-62.703125-24.898438-7.5 0-14.101562 3.601562-18.902343 10.199219-10.796876 15-10.796876 
                47.101562-6.296876 67.199219-25.503906 33.601562-32.402343 82.5-17.101562 123 14.398438 38.699218 46.800781 65.703124 92.402344 78-3.722656 
                6.511718-6.667969 14.914062-7.828125 26.285156-9.210938 3.175781-17.199219 4.210937-24.628907 2.027344-7.835937-2.316407-13.941406-7.546876-19.246093-16.46875-11.914063-20.015626-32.207031-36.355469-55.3125-34.230469l2.636719 
                29.882812c10.699218-.980469 21.347656 10.339844 26.878906 19.671875 9.125 15.367188 21.417968 25.445313 36.546875 29.914063 11.230469 3.308593 
                21.496093 3.230469 32.550781.871093v40.449219c-87.300781-30.601562-151-114-151-211.800781 0-124.199219 101.800781-227 226-227s226 102.800781 226 
                227c0 97.800781-63.699219 181.199219-151 211.800781zm0 0"
      />
    </SvgIcon>
  );
};

export const RedirectIcon = () => {
  return (
    <SvgIcon
      fontSize="large"
      viewBox="0 0 512 512"
      style={{ color: 'black', fontSize: 29 }}
    >
      <path
        d="m512.453125 260.023438v171.984374c0 44.105469-35.882813 79.992188-79.992187 79.992188h-351.96875c-44.105469 
                0-79.992188-35.886719-79.992188-79.992188v-351.96875c0-44.109374 35.886719-79.9960932 79.992188-79.9960932h228.980468c11.046875 
                0 20 8.9570312 20 20.0000002 0 11.046875-8.953125 20-20 20h-228.980468c-22.054688 0-39.996094 17.941406-39.996094 39.996093v351.96875c0 
                22.054688 17.941406 39.996094 39.996094 39.996094h351.96875c22.054687 0 39.996093-17.941406 39.996093-39.996094v-171.984374c0-11.046876 
                8.953125-20 20-20 11.042969 0 19.996094 8.953124 19.996094 20zm-45.886719-146.144532c3.800782 3.777344 5.894532 8.789063 5.894532 14.109375 
                0 5.324219-2.09375 10.332031-5.894532 14.109375l-80.199218 79.695313c-7.832032 7.785156-7.871094 20.449219-.085938 28.28125 3.90625 3.933593 
                9.046875 5.902343 14.183594 5.902343 5.097656 0 10.195312-1.9375 14.097656-5.8125l80.199219-79.695312c11.410156-11.34375 17.695312-26.425781 
                17.695312-42.480469 0-16.054687-6.285156-31.140625-17.695312-42.480469l-80.199219-79.695312c-7.832031-7.785156-20.496094-7.746094-28.28125.089844-7.785156 
                7.835937-7.746094 20.496094.085938 28.28125zm-43.105468 13.15625c0-11.046875-8.953126-20-19.996094-20-72.113282 0-139.910156 28.082032-190.902344 79.074219s-79.074219 
                118.789063-79.074219 190.902344v15c0 11.042969 8.953125 19.996093 20 19.996093 11.042969 0 19.996094-8.953124 19.996094-19.996093v-15c0-126.8125 103.167969-229.980469 
                229.980469-229.980469 11.042968 0 19.996094-8.953125 19.996094-19.996094zm0 0"
      />
    </SvgIcon>
  );
};

const LinkedInIcon = () => {
  return (
    <SvgIcon fontSize="large" viewBox="0 0 512 512" style={{ color: 'black' }}>
      <path
        d="m452 512h-392c-33.085938 0-60-26.914062-60-60v-392c0-33.085938 26.914062-60 60-60h392c33.085938
                0 60 26.914062 60 60v392c0 33.085938-26.914062 60-60 60zm-392-472c-11.027344 0-20 8.972656-20 20v392c0 
                11.027344 8.972656 20 20 20h392c11.027344 0 20-8.972656 20-20v-392c0-11.027344-8.972656-20-20-20zm353.667969 
                373.332031v-118c0-54.402343-44.261719-98.667969-98.667969-98.667969s-98.667969 44.265626-98.667969 98.667969v118c0 
                11.046875 8.953125 20 20 20s20-8.953125 20-20v-118c0-32.347656 26.320313-58.667969 58.667969-58.667969s58.667969 
                26.320313 58.667969 58.667969v118c0 11.046875 8.953125 20 20 20s20-8.953125 20-20zm-275.335938 0v-196.664062c0-11.046875-8.953125-20-20-20s-20 
                8.953125-20 20v196.667969c0 11.042968 8.953125 20 20 20s20-8.957032 20-20.003907zm-20.003906-295.332031c-11.042969 0-20 8.953125-20 20s8.957031 
                20 20 20c11.046875 0 20-8.953125 20-20s-8.953125-20-20-20zm0 0"
      />
    </SvgIcon>
  );
};

const EmailIcon = () => {
  return (
    <SvgIcon fontSize="large" viewBox="0 0 512 512" style={{ color: 'black' }}>
      <path
        d="M467,61H45C20.218,61,0,81.196,0,106v300c0,24.72,20.128,45,45,45h422c24.72,0,45-20.128,45-45V106
                C512,81.28,491.872,61,467,61z M460.786,91L256.954,294.833L51.359,91H460.786z M30,399.788V112.069l144.479,143.24L30,399.788z
                M51.213,421l144.57-144.57l50.657,50.222c5.864,5.814,15.327,5.795,21.167-0.046L317,277.213L460.787,421H51.213z M482,399.787
                L338.213,256L482,112.212V399.787z"
      />
    </SvgIcon>
  );
};

export const iconMap = (icon) => {
  const iconMapping = {
    chevron: ChevronIcon(),
    menu: MenuIcon(),
    outlinedarrow: OutlinedArrowIcon(),
    toolbox: ToolBoxIcon(),
    book: BookIcon(),
    webdev: WebDevIcon(),
    arrow: ArrowIcon(),
    github: GitHubIcon(),
    website: RedirectIcon(),
    linkedin: LinkedInIcon(),
    email: EmailIcon(),
  };

  return iconMapping[icon];
};

export default iconMap;
